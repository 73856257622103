<div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>
<strong>
  <span>Copyright &copy; {{ currentYear }}</span>
  <a
    href="https://erdkse.com"
    rel="noopener noreferrer"
    style="margin: 0"
    target="_blank"
  >
    erdkse.com</a
  >
  <span>.</span>
</strong>
<span> All rights reserved.</span>
