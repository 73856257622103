<div class="card card-outline card-primary">
  <div class="card-header text-center">
    <a [routerLink]="['/']" class="h1"><b>Admin</b>LTE</a>
  </div>
  <div class="card-body">
    <p class="login-box-msg">Sign in to start your session</p>

    <form (ngSubmit)="loginByAuth()" [formGroup]="loginForm">
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="email"
          placeholder="Email"
          type="email"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-envelope"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="password"
          placeholder="Password"
          type="password"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <div class="icheck-primary">
            <input id="remember" type="checkbox"/>
            <label for="remember"> Remember Me </label>
          </div>
        </div>
        <!-- /.col -->
        <div class="col-5">
          <pf-button
            [block]="true"
            [disabled]="isFacebookLoading || isGoogleLoading"
            [loading]="isAuthLoading"
            [type]="'submit'"
          >
            Sign In
          </pf-button>
        </div>
        <!-- /.col -->
      </div>
    </form>

    <div class="social-auth-links text-center mb-3">
      <pf-button
        (click)="loginByFacebook()"
        [block]="true"
        [disabled]="isAuthLoading || isGoogleLoading"
        [loading]="isFacebookLoading"
        class="mb-2"
      >
        <i class="fab fa-facebook mr-2"></i>
        Sign in using Facebook
      </pf-button>
      <pf-button
        (click)="loginByGoogle()"
        [block]="true"
        [disabled]="isAuthLoading || isFacebookLoading"
        [loading]="isGoogleLoading"
        theme="danger"
      >
        <i class="fab fa-google mr-2"></i>
        Sign in using Google+
      </pf-button>
    </div>

    <p class="mb-1">
      <a [routerLink]="['/forgot-password']">I forgot my password</a>
    </p>
    <p class="mb-0">
      <a [routerLink]="['/register']" class="text-center">
        Register a new membership
      </a>
    </p>
  </div>
  <!-- /.login-card-body -->
</div>
